import type { ReactNode } from 'react'

import TEST_IDS from '../../constants/testIds'
import type { CreateComponentsConfig } from '../../types'

import styles from '../GenericStyles.module.scss'

interface ParagraphProps {
	children: ReactNode
	customProps?: CreateComponentsConfig['paragraphProps']
}

/** Renders a paragraph element with literal text content */
const Paragraph = ({ children, customProps }: ParagraphProps) => {
	const extraProps = { className: styles.paragraph, ...customProps }
	return (
		<p data-testid={TEST_IDS.PARAGRAPH} {...extraProps}>
			{children}
		</p>
	)
}

export default Paragraph
