import type { ReactNode } from 'react'
import React from 'react'

import TEST_IDS from '../../constants/testIds'
import type { CreateComponentsConfig } from '../../types'

interface ListProps {
	children: ReactNode
	customProps?: CreateComponentsConfig['listProps']
}

export const List = ({ children, customProps }: ListProps) => {
	const extraProps = { ...customProps }
	return (
		<ul data-testid={TEST_IDS.LIST} {...extraProps}>
			{children}
		</ul>
	)
}

const elementComponentMap: Record<
	string,
	React.FunctionComponent<ListProps>
> = {
	ul: List,
}

export const createListElement = (
	key: string,
	tagName: string,
	children: ReactNode,
	customProps?: CreateComponentsConfig['listProps']
) => {
	const Component = elementComponentMap[tagName]
	if (Component) {
		return (
			<Component key={key} customProps={customProps}>
				{children}
			</Component>
		)
	}

	return React.createElement(tagName, { key }, children)
}
