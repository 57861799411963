import { getMasterData } from 'master-data'
// eslint-disable-next-line mng/no-next-link
import NextLink from 'next/link'

import type { ServerHomeLinkProps } from './types'

export function ServerHomeLink(
	props: React.PropsWithChildren<ServerHomeLinkProps>
) {
	const { isNextLink, children, prefetch, ...rest } = props
	const { country } = getMasterData()
	const countryISO = country.countryISO.toLocaleLowerCase()
	const languageISO = country.languageISO

	const href = `/${countryISO}/${languageISO}`

	if (isNextLink) {
		return <NextLink {...rest} prefetch={prefetch} href={href} />
	}

	return (
		<a {...rest} href={href}>
			{children}
		</a>
	)
}
