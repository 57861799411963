import createComponents from 'labels/lib/createComponents'
import replacePlaceholders from 'labels/lib/replacePlaceholders'
import type { ReactNode } from 'react'

import type { CreateComponentsConfig } from '../../types'

export interface LabelProps extends CreateComponentsConfig {
	/** React children object with the string that will be translated */
	children: ReactNode
	/** The config object used to replace placeholders */
	placeholderValues?: Record<string | number, string | number>
}

/**
 * @example
 *  const translatedKey = 'Hola don <i>%1$s</i>, <a name=subscribe>subscribete aqui</a>'
 *  <Label
 *    placeholderValues={{
 *      1: Pepito
 *      subscribe: link-to-subscribe.html
 *    }}
 *    italicProps={}
 *    boldProps={}
 *    linkProps={}
 *    paragraphProps={}
 *    buttonProps={}
 *    underlineProps={}
 *    tableProps={}
 *    listProps={}
 *    containerProps={}
 *    sectionProps={}
 *    imageProps={}
 *  >
 *    {translatedKey}
 *  </Label>
 *
 * Entry point to create the translated label. Returns an element with the parsed literal with the placeholders replaced
 */
const Label = ({ children, placeholderValues, ...config }: LabelProps) => {
	if (typeof children !== 'string') {
		return null
	}

	const parsedLiteral = replacePlaceholders(children, placeholderValues)

	return createComponents(parsedLiteral, config)
}

export default Label
