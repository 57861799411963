import { cx } from 'utils/cx'
import { ClientLink } from 'utils/link/client'
import { ServerLink } from 'utils/link/server'

import TEST_IDS from '../../constants/testIds'
import type { LinkProps } from '../../types'

import styles from '../GenericStyles.module.scss'

interface AnchorProps {
	children: React.ReactNode
	href: string
	isClient?: boolean
	customProps?: LinkProps
}

/** Renders a link with the href provided as a link and the literal text */
const Anchor = ({ children, href, isClient, customProps }: AnchorProps) => {
	const { withLegacyLocale, className, ...restProps } = customProps ?? {}
	const linkProps = {
		...restProps,
		className: cx(styles.anchor, className),
	} satisfies LinkProps

	return isClient ? (
		<ClientLink
			href={href ?? ''}
			withLegacyLocale={withLegacyLocale}
			linkProps={{ 'data-testid': TEST_IDS.LINK, ...linkProps }}
			{...restProps}
		>
			{children}
		</ClientLink>
	) : (
		<ServerLink
			href={href ?? ''}
			withLegacyLocale={withLegacyLocale}
			linkProps={{ 'data-testid': TEST_IDS.LINK, ...linkProps }}
			{...restProps}
		>
			{children}
		</ServerLink>
	)
}

export default Anchor
