import { getMasterData } from 'master-data'

import { Link } from '../Link'
import type { ServerLinkProps } from './types'

export function ServerLink(props: ServerLinkProps): React.ReactNode {
	const masterData = getMasterData()

	return <Link {...props} masterData={masterData} />
}
