import type { ReactNode } from 'react'

import TEST_IDS from '../../constants/testIds'
import type { CreateComponentsConfig } from '../../types'

import styles from '../GenericStyles.module.scss'

interface UnderlineProps {
	children: ReactNode
	customProps?: CreateComponentsConfig['underlineProps']
}

export const Underline = ({ children, customProps }: UnderlineProps) => {
	const extraProps = { className: styles.underline, ...customProps }
	return (
		<span data-testid={TEST_IDS.UNDERLINE} {...extraProps}>
			{children}
		</span>
	)
}
