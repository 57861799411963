import type { ReactNode } from 'react'
import React from 'react'

import TEST_IDS from '../../constants/testIds'
import type { CreateComponentsConfig } from '../../types'

interface TableProps {
	children: ReactNode
	customProps?: CreateComponentsConfig['tableProps']
}

export const Table = ({ children, customProps }: TableProps) => {
	const extraProps = { ...customProps }
	return (
		<table data-testid={TEST_IDS.TABLE} {...extraProps}>
			{children}
		</table>
	)
}

const elementComponentMap: Record<
	string,
	React.FunctionComponent<TableProps>
> = {
	table: Table,
}

export const createTableElement = (
	key: string,
	tagName: string,
	children: ReactNode,
	customProps?: CreateComponentsConfig['tableProps']
) => {
	const Component = elementComponentMap[tagName]
	if (Component) {
		return (
			<Component key={key} customProps={customProps}>
				{children}
			</Component>
		)
	}

	return React.createElement(tagName, { key }, children)
}
