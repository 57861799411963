/**
 * Returns in renderList parameter the literal with the replaced values.
 * The placeholders are by lokalise definition like: %i$s
 * See https://docs.lokalise.com/en/articles/1400511-lokalise-placeholders for reference
 */
const matchParams = (
	placeholderValues: Record<string | number, string | number>,
	literal: string,
	regExp: RegExp
): string =>
	literal.replaceAll(
		regExp,
		(matcher, labelValue, link, linkIdentifier, linkName, linkValue) => {
			const hasHrefAlias =
				linkIdentifier === 'href' && linkName?.startsWith('#')
			let aliasName = linkName
			if (hasHrefAlias) {
				aliasName = linkName.slice(1)
			}
			if (link) {
				if (linkIdentifier === 'name' || hasHrefAlias) {
					return `<a link="${placeholderValues[aliasName]}">${matchParams(
						placeholderValues,
						linkValue,
						regExp
					)}</a>`
				}
				return matcher
			}
			return placeholderValues !== null &&
				placeholderValues[labelValue] !== undefined
				? placeholderValues[labelValue].toString()
				: ''
		}
	)

/**
 * Replace all the placeholders by the content provided in placeholderValues.
 * @example:
 *  input:
 *    literal: '<p>Hi [%1$s], <b>[%2$s]</b> your text <a name="linkHere">here</a></p>'
 *    placeholderValues: {
 *      linkHere: '/path/to/target',
 *      1: 'Peter',
 *      2: 'search',
 *    }
 *  output
 *    '<p>Hi Peter, <b>search</b> your text <a link="/path/to/target">here</a> </p> <p>Another
 *    paragraph</p>';
 */
const replacePlaceholders = (
	literal: string,
	placeholderValues?: Record<string | number, string | number>
): string => {
	// Matches any placeholder (%_$[s|d|i]) or any link
	// $i format is used by apps developers to manage plurals
	// with this change content team can create the same keys for all platforms
	const findPlaceholders = /%(\d+)\$[sdi]|<(a)\s(.*?)="(.*?)">(.*?)<\/a>/g

	if (!placeholderValues) {
		return literal
	}

	return matchParams(placeholderValues, literal, findPlaceholders)
}

export default replacePlaceholders
