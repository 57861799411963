import type { ReactNode } from 'react'

import TEST_IDS from '../../constants/testIds'
import type { CreateComponentsConfig } from '../../types'

import styles from '../GenericStyles.module.scss'

interface BoldProps {
	children: ReactNode
	customProps?: CreateComponentsConfig['boldProps']
}

/** Renders a bold element with literal text content */
const Bold = ({ children, customProps }: BoldProps) => {
	const extraProps = { className: styles.bold, ...customProps }

	return (
		<span data-testid={TEST_IDS.BOLD} {...extraProps}>
			{children}
		</span>
	)
}

export default Bold
