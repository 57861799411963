import type { ReactNode } from 'react'

import TEST_IDS from '../../constants/testIds'
import type { CreateComponentsConfig } from '../../types'

interface ContainerProps {
	children: ReactNode
	customProps?: CreateComponentsConfig['containerProps']
}

export const Container = ({ children, customProps }: ContainerProps) => {
	return (
		<div data-testid={TEST_IDS.CONTAINER} {...customProps}>
			{children}
		</div>
	)
}
