import type { ReactNode } from 'react'

import TEST_IDS from '../../constants/testIds'
import type { CreateComponentsConfig } from '../../types'

interface SectionProps {
	children: ReactNode
	customProps?: CreateComponentsConfig['sectionProps']
}

export const Section = ({ children, customProps }: SectionProps) => {
	return (
		<div data-testid={TEST_IDS.SECTION} {...customProps}>
			{children}
		</div>
	)
}
