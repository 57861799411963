import type { ReactNode } from 'react'

import TEST_IDS from '../../constants/testIds'
import type { CreateComponentsConfig } from '../../types'

interface SubtitleProps {
	children: ReactNode
	customProps?: CreateComponentsConfig['subtitleProps']
}

/** Renders a bold element with literal text content */
const Subtitle = ({ children, customProps }: SubtitleProps) => {
	const extraProps = { ...customProps }

	return (
		<h3 data-testid={TEST_IDS.SUBTITLE} {...extraProps}>
			{children}
		</h3>
	)
}

export default Subtitle
