export const PROPS_REGEX = {
	hrefProp: /href="([^"]+)"/g,
	linkProp: /link="([^"]+)"/g,
	targetProp: /target="([^"]+)"/g,
	classProp: /class="([^"]+)"/g,
	srcProp: /src="([^"]+)"/g,
	altProp: /alt="([^"]+)"/g,
}

export const HTML_TAGS_REGEX = [
	/<\s*p[^>]*>(.*?)<\s*\/\s*p>/g,
	/<\s*b[^>]*>(.*?)<\s*\/\s*b>/g,
	/<\s*i[^>]*>(.*?)<\s*\/\s*i>/g,
	/<\s*a[^>]*>(.*?)<\s*\/\s*a>/g,
	/<\s*h2[^>]*>(.*?)<\s*\/\s*h2>/g,
	/<\s*h3[^>]*>(.*?)<\s*\/\s*h3>/g,
	/<\s*button[^>]*>(.*?)<\s*\/\s*button>/g,
	/<\s*strong[^>]*>(.*?)<\s*\/\s*strong>/g,
	/<\s*u[^>]*>(.*?)<\s*\/\s*u>/g,
	/<\s*table[^>]*>(.*?)<\s*\/\s*table>/g,
	/<\s*tbody[^>]*>(.*?)<\s*\/\s*tbody>/g,
	/<\s*thead[^>]*>(.*?)<\s*\/\s*thead>/g,
	/<\s*th[^>]*>(.*?)<\s*\/\s*th>/g,
	/<\s*tr[^>]*>(.*?)<\s*\/\s*tr>/g,
	/<\s*td[^>]*>(.*?)<\s*\/\s*td>/g,
	/<\s*ul[^>]*>(.*?)<\s*\/\s*ul>/g,
	/<\s*ol[^>]*>(.*?)<\s*\/\s*ol>/g,
	/<\s*li[^>]*>(.*?)<\s*\/\s*li>/g,
	/<\s*div[^>]*>(.*?)<\s*\/\s*div>/g,
	/<\s*section[^>]*>(.*?)<\s*\/\s*section>/g,
	/<\s*img[^>]*\/?>/g,
	/<\s*br[^>]*\/?>/g,
]
