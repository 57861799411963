/* eslint-disable no-use-before-define */
// Rule disabled for manual recursion
import type { ReactElement, ReactNode } from 'react'

import Anchor from '../components/Anchor/Anchor'
import Bold from '../components/Bold/Bold'
import { ClientButton } from '../components/ClientButton/ClientButton'
import { Container } from '../components/Container/Container'
import { Image } from '../components/Image/Image'
import Italic from '../components/Italic/Italic'
import { createListElement } from '../components/List/List'
import Paragraph from '../components/Paragraph/Paragraph'
import { Section } from '../components/Section/Section'
import Subtitle from '../components/Subtitle/Subtitle'
import { createTableElement } from '../components/Table/Table'
import Title from '../components/Title/Title'
import { Underline } from '../components/Underline/Underline'
import { HTML_TAGS_REGEX, PROPS_REGEX } from '../constants/regex'
import type { CreateComponentsConfig } from '../types'

const COMPLETE_MATCH = 0
const REGEX_TEXT_IDX = 1

const BOLD_BEGIN = '<b'
const STRONG_BEGIN = '<strong'

const ITALIC_BEGIN = '<i'
const UNDERLINE_BEGIN = '<u'

const LINK_BEGIN = '<a'
const REGEX_LINK_NAME_IDX = 1
const REGEX_LINK_TEXT_IDX = 1

const TITLE_BEGIN = '<h2'

const SUBTITLE_BEGIN = '<h3'

const PARAGRAPH_BEGIN = '<p'

const BUTTON_BEGIN = '<button'

const TABLE_BEGIN = '<table'
const TABLE_BODY_BEGIN = '<tbody'
const TABLE_HEAD_BEGIN = '<thead'
const TABLE_TH_BEGIN = '<th'
const TABLE_TR_BEGIN = '<tr'
const TABLE_TD_BEGIN = '<td'

const LIST_UL_BEGIN = '<ul'
const LIST_OL_BEGIN = '<ol'
const LIST_LI_BEGIN = '<li'

const CONTAINER_BEGIN = '<div'
const SECTION_BEGIN = '<section'

const IMAGE_BEGIN = '<img'
const LINEBREAK_BEGIN = '<br'

function getMatch(regex: RegExp, match: RegExpExecArray) {
	regex.lastIndex = 0
	const linkMatch = regex.exec(match[COMPLETE_MATCH])
	return (linkMatch && linkMatch[REGEX_LINK_NAME_IDX]) || ''
}

function createBoldComponent(
	config: CreateComponentsConfig,
	counter: number,
	match: RegExpExecArray
) {
	const customBoldProps = config.boldProps
	return (
		<Bold key={`b-${counter}`} customProps={customBoldProps}>
			{match[REGEX_TEXT_IDX]}
		</Bold>
	)
}

function createAnchorComponent(
	config: CreateComponentsConfig,
	counter: number,
	match: RegExpExecArray
) {
	const href = getMatch(PROPS_REGEX.hrefProp, match)
	const link = getMatch(PROPS_REGEX.linkProp, match)
	const target = getMatch(PROPS_REGEX.targetProp, match)
	const customLinkProps = { target, ...config.linkProps }
	const { isClient } = config
	return (
		<Anchor
			key={`a-${counter}`}
			href={href || link}
			isClient={isClient}
			customProps={customLinkProps}
		>
			{createComponents(match[REGEX_LINK_TEXT_IDX], config)}
		</Anchor>
	)
}

function createItalicComponent(
	config: CreateComponentsConfig,
	counter: number,
	match: RegExpExecArray
) {
	const customItalicProps = config.italicProps
	return (
		<Italic key={`i-${counter}`} customProps={customItalicProps}>
			{match[REGEX_TEXT_IDX]}
		</Italic>
	)
}

function createTitleComponent(
	config: CreateComponentsConfig,
	counter: number,
	match: RegExpExecArray
) {
	const customTitleProps = config.titleProps
	return (
		<Title key={`title-${counter}`} customProps={customTitleProps}>
			{createComponents(match[REGEX_TEXT_IDX], config)}
		</Title>
	)
}

function createSubtitleComponent(
	config: CreateComponentsConfig,
	counter: number,
	match: RegExpExecArray
) {
	const customSubtitleProps = config.subtitleProps
	return (
		<Subtitle key={`subtitle-${counter}`} customProps={customSubtitleProps}>
			{createComponents(match[REGEX_TEXT_IDX], config)}
		</Subtitle>
	)
}

function createParagraphComponent(
	config: CreateComponentsConfig,
	counter: number,
	match: RegExpExecArray
) {
	const customProps = config.paragraphProps
	return (
		<Paragraph key={`p-${counter}`} customProps={customProps}>
			{createComponents(match[REGEX_TEXT_IDX], config)}
		</Paragraph>
	)
}

function createButtonComponent(
	config: CreateComponentsConfig,
	counter: number,
	match: RegExpExecArray
) {
	const customButtonProps = config.buttonProps
	return (
		<ClientButton key={`btn-${counter}`} customProps={customButtonProps}>
			{match[REGEX_TEXT_IDX]}
		</ClientButton>
	)
}

function createUnderlineComponent(
	config: CreateComponentsConfig,
	counter: number,
	match: RegExpExecArray
) {
	const customUnderlineProps = config.underlineProps
	return (
		<Underline key={`u-${counter}`} customProps={customUnderlineProps}>
			{createComponents(match[REGEX_TEXT_IDX], config)}
		</Underline>
	)
}

function createTableComponent(
	config: CreateComponentsConfig,
	counter: number,
	match: RegExpExecArray,
	tag: string
) {
	const HTMLTag = tag.replace('<', '')
	const customTableProps = config.tableProps
	const key = `${HTMLTag}-${counter}`
	return createTableElement(
		key,
		HTMLTag,
		createComponents(match[REGEX_TEXT_IDX], config),
		customTableProps
	)
}

function createListComponent(
	config: CreateComponentsConfig,
	counter: number,
	match: RegExpExecArray,
	tag: string
) {
	const HTMLTag = tag.replace('<', '')
	const customListProps = config.listProps
	const key = `${HTMLTag}-${counter}`
	return createListElement(
		key,
		HTMLTag,
		createComponents(match[REGEX_TEXT_IDX], config),
		customListProps
	)
}

function createContainerComponent(
	config: CreateComponentsConfig,
	counter: number,
	match: RegExpExecArray
) {
	const className = getMatch(PROPS_REGEX.classProp, match)
	const customContainerProps = { className, ...config.containerProps }
	return (
		<Container key={`div-${counter}`} customProps={customContainerProps}>
			{createComponents(match[REGEX_TEXT_IDX], config)}
		</Container>
	)
}

function createSectionComponent(
	config: CreateComponentsConfig,
	counter: number,
	match: RegExpExecArray
) {
	const className = getMatch(PROPS_REGEX.classProp, match)
	const customSectionProps = { className, ...config.sectionProps }
	return (
		<Section key={`section-${counter}`} customProps={customSectionProps}>
			{createComponents(match[REGEX_TEXT_IDX], config)}
		</Section>
	)
}

function createImageComponent(
	config: CreateComponentsConfig,
	counter: number,
	match: RegExpExecArray
) {
	const src = getMatch(PROPS_REGEX.srcProp, match)
	const alt = getMatch(PROPS_REGEX.altProp, match)
	const customImageProps = { src, alt, ...config.imageProps }

	return (
		<Image
			key={`img-${counter}`}
			src={src}
			alt={alt}
			customProps={customImageProps}
		/>
	)
}

function createLineBreakComponent() {
	return <br />
}

/** Returns the proper component depending on the match parameter. */
const createComponent = (
	/** The target string that will be replaced by the component */
	match: RegExpExecArray,
	/** The config object used to add props to the components */
	config: CreateComponentsConfig,
	/** Used to create the unique key */
	counter = 0
) => {
	const cleanedMatch = match[0].replace(/\/\s*>/, '>')
	const beginWith = cleanedMatch.split(/\s|>/)[0]
	const componentCreators: Record<string, () => React.JSX.Element> = {
		[BOLD_BEGIN]: () => createBoldComponent(config, counter, match),
		[STRONG_BEGIN]: () => createBoldComponent(config, counter, match),
		[LINK_BEGIN]: () => createAnchorComponent(config, counter, match),
		[ITALIC_BEGIN]: () => createItalicComponent(config, counter, match),
		[TITLE_BEGIN]: () => createTitleComponent(config, counter, match),
		[SUBTITLE_BEGIN]: () => createSubtitleComponent(config, counter, match),
		[PARAGRAPH_BEGIN]: () => createParagraphComponent(config, counter, match),
		[BUTTON_BEGIN]: () => createButtonComponent(config, counter, match),
		[UNDERLINE_BEGIN]: () => createUnderlineComponent(config, counter, match),
		[CONTAINER_BEGIN]: () => createContainerComponent(config, counter, match),
		[SECTION_BEGIN]: () => createSectionComponent(config, counter, match),
		[IMAGE_BEGIN]: () => createImageComponent(config, counter, match),
		[LINEBREAK_BEGIN]: () => createLineBreakComponent(),
		[TABLE_BEGIN]: () =>
			createTableComponent(config, counter, match, TABLE_BEGIN),
		[TABLE_BODY_BEGIN]: () =>
			createTableComponent(config, counter, match, TABLE_BODY_BEGIN),
		[TABLE_HEAD_BEGIN]: () =>
			createTableComponent(config, counter, match, TABLE_HEAD_BEGIN),
		[TABLE_TH_BEGIN]: () =>
			createTableComponent(config, counter, match, TABLE_TH_BEGIN),
		[TABLE_TR_BEGIN]: () =>
			createTableComponent(config, counter, match, TABLE_TR_BEGIN),
		[TABLE_TD_BEGIN]: () =>
			createTableComponent(config, counter, match, TABLE_TD_BEGIN),
		[LIST_UL_BEGIN]: () =>
			createListComponent(config, counter, match, LIST_UL_BEGIN),
		[LIST_OL_BEGIN]: () =>
			createListComponent(config, counter, match, LIST_OL_BEGIN),
		[LIST_LI_BEGIN]: () =>
			createListComponent(config, counter, match, LIST_LI_BEGIN),
	}
	return componentCreators[beginWith]()
}

function getFirstOccurrenceOf(regexList: RegExp[], literal: string) {
	return regexList.reduce(
		(lastMatch: RegExpExecArray | null, regexp: RegExp) => {
			regexp.lastIndex = 0
			const current = regexp.exec(literal)
			let result = !current ? lastMatch : current
			if (current && lastMatch) {
				result = current?.index < lastMatch?.index ? current : lastMatch
			}
			return result
		},
		null
	)
}

/** Transforms from a literal to a valid React.ReactNode with the proper parsed sub-elements */
const createComponents = (
	/** The target string that will be replaced by the component */
	literalValue: string,
	/** The config object used to add props to the components */
	config: CreateComponentsConfig = {},
	/** Output component array used in the recursive calls */
	renderList: ReactNode[] = [],
	/** Unique number to create the key */
	counter = 0
): ReactElement => {
	// Matches any <h2>, <h3>, <a>, <p>, <b>, <i>, <button>, <strong>, <u>, <table>,
	// <tbody>, <thead>, <th>, <tr>, <td>, <ul>, <ol>, <li>, <div>, <section>, <img>
	// tags

	const match = getFirstOccurrenceOf(HTML_TAGS_REGEX, literalValue)

	if (match) {
		renderList.push(literalValue.substring(0, match.index))
		renderList.push(createComponent(match, config, counter))
		const suffix = literalValue.substring(
			match.index + match[COMPLETE_MATCH].length,
			literalValue.length
		)
		return createComponents(suffix, config, renderList, counter + 1)
	}
	if (literalValue) {
		renderList.push(literalValue)
	}
	// Fragment needed to transform the array into a valid React.ReactNode
	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{renderList}</>
}

export default createComponents
